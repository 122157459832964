import { css } from '@emotion/core';
import { theme } from '../../../../../layouts/main.layout';

export const footer = css`
  /* static css */

  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: black;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;

  @media screen and (max-width: 640px) {
    flex-direction: column;

    * {
      margin: 10px 0;
    }
  }
`;

export const links = css`
  color: ${theme.colors.primaryTextGray};
  font-size: ${theme.fontSizes[1]}px;

  :hover {
    color: ${theme.colors.vozziOrange};
  }

  :not(:last-child):after {
    content: '|';
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const thumbnailLinks = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: ${theme.colors.primaryTextGray};
  color: white;

  :hover {
    background-color: ${theme.colors.vozziOrange};
  }
`;

export const footerLabelText = css`
  font-size: ${theme.fontSizes[1]}px;
  color: white;
`;

export const backroundStyle = css`
  height: 100px;
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom;
  background-image: url(/img/website-footer-3@2x.png);

  @media screen and (max-width: 560px) {
    height: 150px;
    padding-bottom: 0%;
    background-repeat: no-repeat !important;
    background-size: 237% 55%;
  }
`;
