import { css } from '@emotion/core';

export const centerVertical = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const centerVertAndHor = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

export const positionNavbar = css`
  position: relative;
  margin-top: 93px !important;

  @media screen and (max-width: 703px) {
    margin-top: 130px;
  }

  @media screen and (min-width: 703px) and (max-width: 884px) {
    margin-top: 154px;
  }

  @media screen and (min-width: 884px) {
    margin-top: 126px;
  }
`;
