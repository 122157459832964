import { atom, selector } from 'recoil';
import axios from 'axios';
import { UserLocationStateI, WebShopI, UserDataI, NetworkI, NavbarI, WholeWebshopStateI, PayslipI } from './main.type';
import to from '../lib/awaitTo';
import '../configs/axios';

export const packages: any = {
  EU1: 'eu1',
  EU2: 'eu2',
  EU3: 'eu3',
  VOZZI40: 'vozzi40',
  VOZZIRS: 'vozziRS',
};

export const choosenHaircut = atom<any>({
  key: 'choosenHaircut',
  default: {
    id: null,
    name: null,
    price: null,
    duration: null,
    iconUrl: null,
  },
});

export const userData = atom<UserDataI>({
  key: 'userData',
  default: {
    firstName: null,
    lastName: null,
    mobileNumber: null,
    email: null,
    address: null,
    city: null,
    postalCode: null,
  },
});

export const webShop = atom<WebShopI>({
  key: 'webShop',
  default: {
    packageDetails: null,
    userExists: null,
    userChecked: false,
    paymentType: null,
  },
});

export const userLocation = atom<UserLocationStateI>({
  key: 'userLocation',
  default: {
    updatedAt: null,
    lat: null,
    lng: null,
  },
});

export const network = atom<NetworkI>({
  key: 'requests',
  default: {
    requests: 0,
  },
});

export const navbar = atom<NavbarI>({
  key: 'navbar',
  default: {
    isOpen: false,
  },
});

export const payslip = atom<PayslipI>({
  key: 'payslip',
  default: {
    price: 0,
    clientBundleId: null,
  },
});

export const wholeWebshopState = selector<WholeWebshopStateI>({
  key: 'wholeWebshopState',
  get: ({ get }) => {
    const userData_ = get(userData);
    const webShop_ = get(webShop);
    const userLocation_ = get(userLocation);
    return {
      userData: userData_,
      webShop: webShop_,
      userLocation: userLocation_,
    };
  },
});

export const pricesData = selector<any>({
  key: 'pricesData',
  get: async () => {
    const [response] = await to(axios.get('/bundles-for-web'));

    if (response) {
      return response.data;
    }

    return null;
  },
});
