import React from 'react';
import Helmet from 'react-helmet';

import { metaData } from './metaData';
import { theme } from '../layouts/main.layout';

export interface MetaObjectI {
  title?: string;
  description?: string;
  keywords?: string;
  lang?: string;
}

export interface OgObjectI {
  title?: string;
  description?: string;
  image?: string;
}

export interface HeadPropsI {
  meta?: MetaObjectI;
  og?: OgObjectI;
  lang?: string;
}

const META = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  KEYWORDS: 'keywords',
  LANG: 'lang',
};

const OG = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  IMAGE: 'image',
};

const Head: React.FC<HeadPropsI> = (props) => {
  const getMetaValue = (type: string): string | undefined => {
    let metaValue;

    switch (type) {
      case META.TITLE:
        {
          metaValue = props.meta?.title ?? metaData.title;
          metaValue += ' | KOKI';
        }
        break;

      case META.DESCRIPTION:
        {
          metaValue = props.meta?.description ?? metaData.description;
        }
        break;

      case META.KEYWORDS:
        {
          metaValue = props.meta?.keywords ?? metaData.keywords;
        }
        break;

      case OG.TITLE:
        {
          metaValue = props.meta?.title ?? metaData.title;
          metaValue += ' | KOKI';
        }
        break;

      case OG.DESCRIPTION:
        {
          metaValue = props.og?.description ?? props.meta?.description ?? metaData.description;
        }
        break;
    }

    return metaValue;
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />

      <meta name="theme-color" content={theme.colors.white} media="(prefers-color-scheme: light)" />
      <meta name="theme-color" content={theme.colors.darkGray} media="(prefers-color-scheme: dark)" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700;800;900&display=swap" rel="stylesheet" />

      <title>{getMetaValue(META.TITLE)}</title>
      <meta name="description" content={getMetaValue(META.DESCRIPTION)} />
      <meta name="keywords" content={getMetaValue(META.KEYWORDS)} />

      <meta property="og:title" content={getMetaValue(OG.TITLE)} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={getMetaValue(OG.DESCRIPTION)} />

      <html lang={props.lang} prefix="og: http://ogp.me/ns#" />
    </Helmet>
  );
};

export { Head };
