import React from 'react';
import { Box } from 'rebass';
import { ThemeProvider } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';

import { HeadPropsI, Head } from '../meta/Head.component';
import { container } from '../shared-styles/container.css';

export const fontFamily = '"Roboto" ,sans-serif';
export const landingTitle = '"ArgentumSans" ,sans-serif';

export type breakpointKeysT = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const breakpoints: any = [
  '20em', // 320px
  '32.5em', // 520px
  '40em', // 640px
  '52em', // 832px
  '64em', // 1024px
  '80em', // 1280px
  '120em', // 1920px
];

breakpoints.xxs = breakpoints[0]; // 320px
breakpoints.xs = breakpoints[1]; // 520px
breakpoints.sm = breakpoints[2]; // 640px
breakpoints.md = breakpoints[3]; // 832px
breakpoints.lg = breakpoints[4]; // 1024px
breakpoints.xl = breakpoints[5]; // 1280px
breakpoints.xxl = breakpoints[6]; // 1920px

// @TODO - try patching the styled-system props so it can recieve the type generic for breakpointsT
export type breakpointsT = typeof breakpoints;

export const theme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    primaryOrange: '#CC3F0C',
    primaryTextGray: '#3b3b3c',
    borderGray: '#aeaeae',
    borderDarkGray: '#3b3a3b',
    lightGray: '#aeaeae',
    mediumGray: '#767576',
    darkGray: '#3b3a3b',
    backgroundLightGray: '#ebebeb',
    gray: '#d8d8d8',
    vozziVividOrange: '#fe7f12',
    vozziOrange: '#f47e20',
    vozziLightOrange: '#ff7f11',
    backgroundGray: '#f9f9f9',
    mainGradientLeft: '#14dee1',
    mainGradientRight: '#130cb7',
    warningRed: '#d0021b',
    boxShadow: '1px 10px 15px -5px rgba(0, 0, 0, 0.1)',
    gradient: 'linear-gradient(to right, #14DEE1 0%, #148DD1 35%, #130CB7 100%)',
    whiteGradient: 'linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%)',
    overlayGradient: 'linear-gradient(to top, #000000 0%, rgba(255, 255, 255, 0) 100%)',
    infoOrange: '#fdf2e8',
    infoBlue: '#e7eef9',
    blue: '#1459c6',
    discountRed: '#cd0010',
  },
  space: [
    // margin and padding
    0, 4, 8, 16, 32, 64, 94, 128, 256,
  ],
  breakpoints: breakpoints as breakpointsT, // Uses (min-width)
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  fonts: {
    body: fontFamily,
    landingTitle: landingTitle,
    heading: fontFamily,
    text: fontFamily,
    monospace: 'monospace',
    sansSerif: 'sans-serif',
  },
  fontSizes: [12, 14, 16, 22, 24, 26, 30, 32, 48, 54, 64, 96, 128],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
    extraBold: 800,
    extraLight: 200,
  },
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: '#CC3F0C',
      borderRadius: '6px',
    },
    outline: {
      color: 'vozziVividOrange',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
  },
};

export type Theme = typeof theme;

const Root = styled.div`
  /* font-family: system-ui, sans-serif; */
  line-height: 1.5;
`;

export const Container = styled.div`
  ${container}
`;

export const globalStyles = css`
  ${emotionNormalize}

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-size: ${theme.fontSizes[2]}px;
    overflow-x: hidden;
    width: 100%;
    font-family: ${theme.fonts.body};
    color: ${theme.colors.primaryTextGray};
  }

  a {
    text-decoration: none;
    color: ${theme.colors.vozziVividOrange};
  }

  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  strong,
  b,
  i,
  u {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin: 0;
  }

  ul,
  ol,
  li {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  @media screen and (max-width: 768px) {
    html {
      max-width: 100vw;
      overflow-x: hidden;
    }
  }

  /* This is css for override Google Map InfoWindow close button X */

  .gm-ui-hover-effect {
    background-color: #dbdbdb !important;
    margin-right: 5px !important;
    border-radius: 25% !important;
  }

  .sweet-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    z-index: 4;
    position: fixed;
  }

  .redSpan {
    background-color: #f47e20;
    display: inline-block;
    padding: 8px;
  }

  .orangeSpan {
    background-color: #e82d00;
    display: inline-block;
    padding: 8px;
  }

  @media (max-width: 800px) {
    .react-switch-bg {
      height: 22px !important;
      width: 50px !important;
    }
    .react-switch-handle {
      height: 20px !important;
      width: 20px !important;
    }
    .orangeSpan {
      padding: 3px 8px;
    }
    .redSpan {
      padding: 3px 8px;
    }
  }
`;

export interface MainLayoutPropsI extends HeadPropsI {
  // ...
}

const MainLayout: React.FC<MainLayoutPropsI> = (props) => {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  return (
    <ThemeProvider theme={theme}>
      <Head lang={lang} meta={props.meta ?? undefined} og={props.og ?? undefined} />
      <Box backgroundColor="backgroundGray">
        <Root>
          <Container>
            <Global styles={globalStyles} />

            {props.children}
          </Container>
        </Root>
      </Box>
    </ThemeProvider>
  );
};

export { MainLayout };
