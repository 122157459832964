import React from 'react';
import { Link } from 'gatsby';
import { useRecoilState } from 'recoil';
import { Box, Flex, Image, Text } from 'rebass';

import { theme } from '../../../../layouts/main.layout';
import { navbar } from '../../../../shared-state/main';
import { MobileContainer } from './css/TopNavigationMenuStyled.css';
import { navBarLink, navButtons, navBarScroll } from './css/topNavigationMenu.css';

const MobileTopNav: React.FC = () => {
  const [navbarState, SetNavbarState] = useRecoilState(navbar);

  const html = document.getElementsByTagName('html');
  html[0].style.overflow = navbarState.isOpen ? 'hidden' : 'auto';

  const navLinks = [
    {
      title: 'O meni',
      to: '/#about',
    },
    {
      title: 'Cenovnik usluga',
      to: '/#pricelist',
    },
    {
      title: 'Kontakt',
      to: '#contact',
    },
  ];

  return (
    <Box>
      <Image
        py={4}
        src="/img/hamburger-icon.png"
        onClick={() =>
          SetNavbarState(() => ({
            isOpen: !navbarState.isOpen,
          }))
        }
        display={{ _: 'block', lg: 'none' }}
        style={{ filter: 'invert(100%)' }}
      />
      <MobileContainer isOpen={navbarState.isOpen}>
        <Box css={navBarScroll}>
          {navLinks.map((link) => {
            return (
              <Link
                css={navBarLink}
                to={link.to}
                onClick={() =>
                  SetNavbarState(() => ({
                    isOpen: !navbarState.isOpen,
                  }))
                }
                key={link.title}
              >
                <Flex css={navButtons} height="50px">
                  <Text color={theme.colors.white} fontWeight={theme.fontWeights.bold}>
                    {link.title}
                  </Text>
                </Flex>
              </Link>
            );
          })}
        </Box>
      </MobileContainer>
    </Box>
  );
};

export default MobileTopNav;
