import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/serialize';
import { color, backgroundImage } from 'styled-system';
import { ExtendableEmotion } from '../../../../../helpers/ExtendableEmotion.interface';
import { extendStyles } from '../../../../../utils/extendStyles.function';
import { fullWidth as fullWidthCss } from '../../../../../shared-styles/hero.css';

export interface HeaderStyledPropsI extends ExtendableEmotion {
  /** component props */

  fullWidth?: boolean;

  /** styled-system props */

  backgroundColor?: string;
  backgroundImage?: string;
}

export const HeaderStyled = styled.header<HeaderStyledPropsI>`
  /* 
   *
   * computed styles 
   *
   */

  ${color}
  ${backgroundImage}

  ${(props): SerializedStyles | string => (props.fullWidth ? fullWidthCss : '')}

  ${(props): SerializedStyles | string => extendStyles(props.extendStyles)}

`;
