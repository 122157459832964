import React from 'react';
import { css } from '@emotion/core';
import { Text, Box, Flex } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, MainLayoutPropsI, theme } from './main.layout';
import { Header } from '../components/layout/top/Header/Header.component';
import { LogoWithNav } from '../components/layout/top/LogoWithNav/LogoWithNav.component';
import { container } from '../shared-styles/container.css';
import { VozziPrimaryButton } from '../components/layout/_/button.component';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import { positionNavbar } from '../shared-styles/positioning.css';

const headerContentCss = css`
  width: 100%;
  text-align: center;
  height: 500px;
  color: ${theme.colors.white};
  text-shadow: 1px 1px 1px black;
`;

const headerCss = css`
  background-size: cover;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  z-index: 3;
`;

const headerSubTitle = css`
  font-size: 22px;
  @media screen and (max-width: 520px) {
    font-size: 18px;
  }

  @media screen and (max-width: 360px) {
    font-size: 16px;
  }
`;

const NOTIFY_ABOUT_NEW_PRICE_KEY = 'new_prices_notify';

interface StandardPageLayoutPropsI extends MainLayoutPropsI {
  mainHeading: string;
  subText?: string;
  subTextTwo?: string;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  backgroundColor?: string;
  buttonText?: any;
  minHeight?: string;
  alignItems?: string;
  justifyContent?: string;
  onClick?: any;
  to?: any;
  pb?: any;
}

export const StandardPageLayout: React.FC<StandardPageLayoutPropsI> = ({
  mainHeading,
  subText,
  subTextTwo,
  backgroundColor,
  backgroundImage = 'bg/vozzi-background-placeholder-img.jpg',
  backgroundImageMobile = 'bg/vozzi-background-placeholder-img.jpg',
  children,
  buttonText,
  minHeight,
  alignItems,
  justifyContent,
  onClick,
  to,
  pb,
  meta,
  og,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImageMobile: allFile(filter: { relativeDirectory: { eq: "bg" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 832, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        backgroundImage: allFile(filter: { relativeDirectory: { eq: "bg" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 2480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const sources = [
    {
      ...data.backgroundImageMobile.edges.find(({ node }: any) => node.relativePath === backgroundImageMobile).node.childImageSharp.fluid,
      media: `(max-width: 832px)`,
    },

    {
      ...data.backgroundImage.edges.find(({ node }: any) => node.relativePath === backgroundImage).node.childImageSharp.fluid,
      media: `(min-width: 832px)`,
    },
  ];

  // useEffect(() => {
  //   if (!sessionStorage.getItem(NOTIFY_ABOUT_NEW_PRICE_KEY)) {
  //     setTimeout(() => {
  //       Dialog.fire({
  //         title: 'Obaveštenje',
  //         html: `
  //         <div style="margin-top:24px;">
  //         <h3>Nova cena frizure "Šišanje" od 1. decembra</h3>
  //         <p style="margin-top: 24px;">Frizura "Šišanje" umesto prethodnih 700 dinara nakon 1. decembra će koštati 800 dinara.</p>
  //         <p style="margin-top: 24px;">Svi termini pre 1. decembra su po starim cenama, bez obzira na cenu koja stoji na sajtu.</p>
  //         </div>
  //         `,
  //         icon: 'info',
  //         confirmButtonColor: '#3fc3ee',
  //         confirmButtonText: 'U redu',
  //       });
  //       sessionStorage.setItem(NOTIFY_ABOUT_NEW_PRICE_KEY, 'true');
  //     }, 3000);
  //   }
  // }, []);

  return (
    <MainLayout meta={meta ?? undefined} og={og ?? undefined}>
      <BackgroundImage fluid={sources} css={headerCss}>
        <Box css={positionNavbar}>
          <Header fullWidth={true}>
            <div css={container}>
              <LogoWithNav absPos={true} />

              <Flex css={headerContentCss} minHeight={minHeight} justifyContent={justifyContent} alignItems={alignItems} pb={pb}>
                <Box>
                  <Text as="h1" fontSize={{ _: 7, sm: 8, md: 9 }} fontWeight={theme.fontWeights.heading}>
                    {mainHeading}
                  </Text>

                  <Text as="p" css={headerSubTitle}>
                    {subText}
                  </Text>

                  {buttonText && to && (
                    <Link to={to}>
                      <VozziPrimaryButton
                        width="200px"
                        onClick={onClick}
                        mt={{ _: 3, xs: 4 }}
                        paddingY={15}
                        fontWeight={theme.fontWeights.bold}
                      >
                        {buttonText}
                      </VozziPrimaryButton>
                    </Link>
                  )}
                </Box>
              </Flex>
            </div>
          </Header>
        </Box>
      </BackgroundImage>

      <main style={{ backgroundColor: backgroundColor }}>{children}</main>
    </MainLayout>
  );
};

StandardPageLayout.defaultProps = {
  minHeight: '393px',
  alignItems: 'center',
  justifyContent: 'center',
};
