import React from 'react';
import { Link } from 'gatsby';
import { LogoWithNavStyled } from './css/LogoWithNavStyled.css';
import { KokiLogo } from '../Logo/logo.component';
import { TopNavigationMenu } from '../TopNavigationMenu/TopNavigationMenu.component';
import { Flex, Box } from 'rebass';
import { container } from '../../../../shared-styles/container.css';
import { border } from './css/logoWithNav.css';

export const LogoWithNav: React.FC<{ absPos?: boolean }> = (props) => {
  return (
    <LogoWithNavStyled absPos={props.absPos}>
      <Box css={border}>
        <Flex justifyContent="space-between" alignItems="center" css={container}>
          <Flex justifyContent="flex-start" alignItems="center">
            <Link to="/">
              <KokiLogo src="/img/logo-koki.png" />
            </Link>
          </Flex>
          <TopNavigationMenu />
        </Flex>
      </Box>
    </LogoWithNavStyled>
  );
};
