import React from 'react';
import { Text, Flex } from 'rebass';

import { FooterStyled, FooterStyledPropsI } from './css/FooterStyled.css';
import { useTranslation } from 'react-i18next';
import { container } from '../../../../shared-styles/container.css';
import { KokiLogo } from '../../../../components/layout/top/Logo/logo.component';

interface FooterPropsI extends FooterStyledPropsI {}

export const Footer: React.FC<FooterPropsI> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <hr
        style={{
          height: '10px',
          backgroundColor: '#CC3F0C',
          border: 'none',
          padding: 0,
          margin: 0,
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
        }}
      />
      <FooterStyled {...props}>
        <Flex justifyContent="space-between" alignItems="center" css={container}>
          <KokiLogo width="50px" src="/img/logo-koki.png" />
          <Text fontSize={{ _: '0.7rem' }}>Muški frizer KOKI. Sva prava zadrzana 2023.</Text>
          {/* <Flex>
          <Text>IG |</Text>
          <Text> FB</Text>
        </Flex> */}
        </Flex>
      </FooterStyled>
    </>
  );
};
