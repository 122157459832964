import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/serialize';
import { css } from '@emotion/core';

export const LogoWithNavStyled = styled.div<{ absPos?: boolean }>`
  /* computed styles */

  ${(props): SerializedStyles | string =>
    props.absPos
      ? css`
          position: fixed;
          left: 0;
          top: 0;
          z-index: 9;
          width: 100%;
        `
      : ``}
`;
