import React from 'react';
import toPx from 'to-px';
import ReactMedia from 'react-media';
import { breakpoints, breakpointKeysT } from '../../layouts/main.layout';

interface ResponsiveContainerProps {
  breakpoint: breakpointKeysT;
  mobileComponent?: JSX.Element;
  desktopComponent?: JSX.Element;
}

export interface ResponsiveMatches {
  desktop: string;
  mobile: string;
}

const onChange = (matches: unknown, breakpoint: string): void => {
  // console.log(`Resp. container breakpoint matches ${toPx(breakpoints[breakpoint])}`, matches);
};

export const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({ breakpoint, children, mobileComponent, desktopComponent }) => {
  const componentsAsProps = mobileComponent && desktopComponent ? true : false;
  if (children && componentsAsProps) {
    throw new Error(
      'ResponsiveContainer error. You cannot pass both children and components as props (mobileComponent & desktopComponent).'
    );
  }
  if ((mobileComponent && !desktopComponent) || (!mobileComponent && desktopComponent)) {
    throw new Error('You cannot pass only mobileComponent or desktopComponent. You need both.');
  }

  const queries = {
    mobile: `(max-width: ${toPx(breakpoints[breakpoint])}px)`,
    desktop: `(min-width: ${toPx(breakpoints[breakpoint])}px)`,
  };

  if (componentsAsProps) {
    return (
      <ReactMedia<ResponsiveMatches>
        queries={queries}
        onChange={(matches): void => onChange(matches, breakpoint)}
        defaultMatches={{ desktop: true }}
      >
        {(matches: ResponsiveMatches): JSX.Element => (
          <>
            {matches.mobile && mobileComponent}
            {matches.desktop && desktopComponent}
          </>
        )}
      </ReactMedia>
    );
  }

  return (
    <ReactMedia<ResponsiveMatches>
      queries={queries}
      onChange={(matches): void => onChange(matches, breakpoint)}
      defaultMatches={{ desktop: true }}
    >
      {children}
    </ReactMedia>
  );
};
