import styled from '@emotion/styled';
import { footer } from './footer.css';

export interface FooterStyledPropsI {
  // nothing here for now.
}

export const FooterStyled = styled.footer<FooterStyledPropsI>`
  ${footer}/* computed styles */
  /* computed styles -- add props if you wish -- hint: Look at PageSection and PageSectionStyled */
`;
