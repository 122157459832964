import axios, { AxiosError } from 'axios';
// TO-DO: why double env file?
import { APIs } from './env';
import { genericToastError, customToastError } from '../lib/dialogs';
import flow from 'lodash/flow';

axios.defaults.baseURL = `${APIs.mainServerApiUrl}`;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const mergeAttributes = ({ attributes, type, ...rest }: any): any => ({
  ...JSON.parse(JSON.stringify(attributes, (_k, v) => (v === null ? '' : v))),
  ...rest,
});

const mergeAttributesList = (data: object[]): any => data.map(mergeAttributes);

const withIncluded = (data: any): any => {
  const keys = Object.keys(data);
  keys.forEach((key) => {
    const datum = data[key];
    if (datum && datum.data && datum.data.attributes) {
      data[key] = mergeAttributes(datum.data);
      return withIncluded(data[key]);
    }
    if (datum && datum.data && Array.isArray(datum.data)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parseData = flow([mergeAttributesList, withIncludedList]);
      data[key] = parseData(datum.data);
    }
  });
  return data;
};

const withIncludedList = (data: object[]): any => {
  return data.map((item) => withIncluded(item));
};

const errorInfoToast = (error: any): any => {
  const message = error.data.message;
  const code = error.data.statusCode;

  if (message && code) {
    customToastError(`Error: ${code}`, message);
  } else {
    genericToastError();
  }
};

const responseSuccessHandler = (response: any): any => {
  if (response.data && response.data.data && Array.isArray(response.data.data)) {
    const { data, meta } = response.data;
    const parseData = flow([mergeAttributesList, withIncludedList]);
    return { data: parseData(data), meta };
  }

  if (response.data && response.data.data && response.data.data.attributes) {
    const parseData = flow([mergeAttributes, (data: object) => withIncluded(data)]);

    return parseData(response.data.data);
  }

  return response;
};

const responseErrorHandler = (error: any): any => {
  if (error.response && error.response.data) {
    errorInfoToast(error.response);

    return Promise.reject(error.response.data);
  }

  return Promise.reject(error);
};

const requestSuccessHandler = (request: any): any => {
  return request;
};

const requestErrorHandler = (error: AxiosError): any => {
  customToastError(`Error: ${error.code}`, 'in requestErrorHandler');

  return Promise.reject(error);
};

axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

axios.interceptors.request.use(requestSuccessHandler, requestErrorHandler);
