import styled from '@emotion/styled';
import { topNavigationMenu } from './topNavigationMenu.css';
import { theme } from '../../../../../layouts/main.layout';

export const TopNavigationMenuStyled = styled.nav`
  ${topNavigationMenu}/* computed styles */
  /* computed styles -- add props if you wish -- hint: Look at PageSection and PageSectionStyled */
`;
export const MobileContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  right: 0;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 30px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
