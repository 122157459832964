import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Box } from 'rebass';

import { PageSection } from '../_/PageSection/PageSection.component';
import SectionHeader from '../SectionHeader/SectionHeader.component';
import { mapWrap, JulayaStyle } from './storesMapSection.css';
import './storesMapSectionLegacy.css';
import { ResponsiveContainer } from '../../../components/functional/ResponsiveContainer.functional';

interface ContactMapProps {
  dontShowTitle?: boolean;
}

export const ContactMap = ({ dontShowTitle }: ContactMapProps): ReactElement => {
  const [map, setMap] = useState(null);

  const mapInitialValues = {
    boundLat: 43.619017555598056,
    boundLng: 21.002403551982223,
    zoom: 15,
  };

  const [mapPosition, setMapPosition] = useState(mapInitialValues);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: mapPosition.boundLat,
      lng: mapPosition.boundLng,
    });

    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // TO-DO: for some reason setting zoom in onLoad function has no effect, we have to give map a second to load for zoom to take effect. Figure out how to fix this.
  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.setZoom(15);
      }, 1000);
    }
  }, [map]);

  return (
    <PageSection extendStyles={mapWrap} paddingTop={1}>
      <Box id="contact"></Box>
      {!dontShowTitle ? <SectionHeader id="map" title="Kontakt" mt={{ _: 5, sm: 5 }} mb={{ _: 4, md: 5 }} /> : null}
      <Box mt={5}>
        <LoadScript googleMapsApiKey="AIzaSyAM59Hdm16dV-sTU_5GgDdSPpQxuE2lmE8">
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '550px',
            }}
            center={{ lat: mapPosition.boundLat, lng: mapPosition.boundLng }}
            zoom={mapPosition.zoom}
            options={{
              styles: JulayaStyle,
            }}
            onLoad={onLoad}
          >
            <Marker position={{ lat: mapPosition.boundLat, lng: mapPosition.boundLng }} />
          </GoogleMap>
        </LoadScript>
        <ResponsiveContainer breakpoint="md">
          {(matches: any): JSX.Element => (
            <>
              {matches.desktop && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 1,
                    top: '30%',
                    left: '15%',
                    backgroundColor: 'white',
                    minWidth: '350px',
                    minHeight: '290px',
                    borderRadius: '8px',
                    padding: '42px 8px 0px 46px',
                    boxShadow: '0px 0px 30px 5px rgba(2, 2, 10, 0.05)',
                  }}
                >
                  <h2>Kako do mene?</h2>

                  <ul>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>Ulica Milana Rajčevića 1</li>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>+381 63 777 1141</li>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>kokibarber@gmail.com</li>
                  </ul>
                </div>
              )}
              {matches.mobile && (
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '50px 0px' }}
                >
                  <h2 style={{ fontSize: '32px', fontWeight: 700 }}>Kontakt</h2>
                  <ul>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>Ulica Milana Rajčevića 1</li>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>
                      <a href="tel:381637771141" style={{ color: '#CC3F0C' }}>
                        +381 63 777 1141
                      </a>
                    </li>
                    <li style={{ fontSize: '1.1rem', marginTop: '25px' }}>
                      <a href="mailto:kokibarber@gmail.com" style={{ color: '#CC3F0C' }}>
                        kokibarber@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
        </ResponsiveContainer>
      </Box>
    </PageSection>
  );
};

export default ContactMap;
