import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Box, Flex, Text } from 'rebass';

import { navBarLink, navButtons } from './css/topNavigationMenu.css';
import { theme } from '../../../../layouts/main.layout';

const DesktopTopNav: React.FC = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      title: 'O meni',
      subTitle: t('home_SubNav'),
      to: '/#about',
    },
    {
      title: 'Cenovnik usluga',
      subTitle: t('ra_SubNav'),
      to: '/#pricelist',
    },
    {
      title: 'Kontakt',
      subTitle: t('partnership_SubNav'),
      to: '#contact',
    },
  ];

  return (
    <Flex>
      {navLinks.map((link) => {
        const Component = Link;
        const border = { borderBottom: `solid 3px ${theme.colors.vozziOrange}` };

        return (
          <Component
            key={link.title}
            activeStyle={border}
            style={{}}
            css={navBarLink}
            to={link.to}
            className={''}
            // onClick={(e: any) => onLinkClick(e, link.subLinks)}
          >
            <Flex css={navButtons} height="90px">
              <Text>{link.title}</Text>
            </Flex>
            {/* {link.subLinks && <SubLinks links={link.subLinks} />} */}
          </Component>
        );
      })}
    </Flex>
  );
};

export default DesktopTopNav;
